<template lang="pug">
  article.fixed.z-20.overlay.bg-white.pt-80.md-pt-120.lg-pt-60.flex.pointer-events-auto(@touchstart="handleTouchStart", @touchend="handleTouchEnd")
    template(v-if="entry")
      //- left col
      section.flex-1.flex.flex-col.relative.pb-100.lg-p-30
        .w-full.flex-1.flex.items-start.lg-items-center.relative.overflow-hidden
          //- image
          resp-img.w-full(:lazy="false", :bg="!isPortrait", fit="object-contain object-center", :image="{url: entry.url, alt: entry.title, width: entry.width, height: entry.height}", :class="{'my-auto': isPortrait && entry.width > entry.height}")
        //- prev/next btn overlays
        nav.absolute.overlay.flex.justify-between.prev-next
          button.prev.w-1x6.lg-w-1x2.lg-flex-1.absolute.left-0.h-full(v-if="siblings[0]", @click="prev", aria-label="Previous Image", style="cursor: w-resize")
          button.next.w-1x6.lg-w-1x2.lg-flex-1.absolute.right-0.h-full(v-if="siblings[1]", @click="next", aria-label="Next Image", style="cursor: e-resize")

      //- right col
      aside.absolute.bottom-0.left-0.w-full.lg-static.lg-w-1x3.xl-w-1x4.lg-border-l.lg-flex.flex-col.lg-max-w-400
        //- info (collapsed on mobile)
        .flex-1.overflow-y-scroll.flex.flex-col
          .flex-1.bg-white.border-t.lg-border-t-0.px-20.lg-p-30.flex.flex-col(v-if="entry")
            header.h-50.lg-h-auto.text-14.relative.flex.items-center.justify-between(@click="mblExpand = !mblExpand")
              .md-hidden INFO
              //- h2.truncate-off.lg-mb-24(v-if="entry.creator.length")
                template(v-for="(creator, i) in entry.creator")
                  | {{ i > 0 ? ', ' : '' }}
                  router-link.hover-underline.focus-underline(:to="{name: 'index', query: {q: creator.title}}") {{ creator.title }}
                //- router-link.hover-underline.focus-underline(:to="{name: 'index', query: {q: creator}}") {{creator}}
              button.transform.lg-hidden(@click.stop="mblExpand = !mblExpand", :class="{'-scale-y-100': mblExpand}") ^
            .flex-1.flex.justify-end.lg-flex.lg-flex-col.lg-justify-start(:class="{'hidden': !mblExpand}")
              //- (roles)
              ul.w-1x2.lg-w-full
                li.mb-24.lg-mb-24(v-for="role in entry.roles")
                  h6.text-10 {{role.label}}
                  ul.text-14
                    li(v-for="creator in role.creators")
                      router-link.hover-underline.focus-underline(:to="{name: 'index', query: {q: creator.title}}") {{creator.title}}
              //- tags
              .lg-flex-1.lg-flex.flex-col.w-1x2.lg-w-full.text-12.pb-24.lg-pb-0
                ul.flex.flex-wrap
                  li.w-full.md-w-1x2(v-for="cat in entry.categories")
                    router-link.hover-underline(:to="`/?categories=` + cat.id", :key="cat.id") {{'#' + cat.title}}
                .mt-auto.w-full
                  span.text-transparent.lg-hidden #
                  a.hover-underline.text-underline.text-gray-500(:href="`mailto:info@elel.app?&subject=Tag(s)%20Suggestions%20-%20Image%20` + entry.id", target="_blank") Suggest Tags
        //- close/next/prev row
        footer.flex.justify-between.items-center.border-t.text-14.bg-white
          button.h-50.lg-h-80.block.px-20.lg-px-30.flex.items-center(@click="close") [X]
          .mr-15.lg-mr-30
            button.hover-underline(v-if="siblings[0]", @click="prev") PREV
            span.inline-block.mx-15(v-if="siblings[0] && siblings[1]") /
            button.hover-underline(v-if="siblings[1]", @click="next") NEXT

    //- Not found
    template(v-else-if="entry === null")
      button.flex-1.flex.items-center.justify-center.text-16.uppercase(@click="$router.push('/')") Not Found :(
</template>

<script>
let lastRt
export default {
  name: 'ImageAsset',
  props: {
    indexEntry: { type: Object, default: undefined },
    siblings: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      entry: undefined,
      mblExpand: false,
      isPortrait: window.innerWidth < 1024,
      touchStartX: 0,
      touchEndX: 0
    }
  },
  computed: {
    creator () {
      return this.entry?.creator?.map(creator => creator.title).join(', ')
    }
  },
  methods: {
    onSwipe (e) {
      return e.deltaX < 0 ? this.next() : this.prev()
    },
    prev () {
      return this.siblings[0] && this.$router.replace('/p/' + this.siblings[0].id)
    },
    next () {
      return this.siblings[1] && this.$router.replace('/p/' + this.siblings[1].id)
    },
    close () {
      return !lastRt?.name ? this.$router.push('/') : this.$router.go(-1)
    },
    onKey (e) {
      return e.keyCode === 39 ? this.next()
        : e.keyCode === 37 ? this.prev()
          : e.keyCode === 27 ? this.close() // esc
            : false
    },
    async get () {
      this.entry = this.indexEntry || await this.$store.dispatch('images/get', this.$route.params.imageasset)
    },
    handleTouchStart (e) {
      this.touchStartX = e.changedTouches[0].screenX
    },
    handleTouchEnd (e) {
      this.touchEndX = e.changedTouches[0].screenX
      if (!window.matchMedia('(min-width: 1024px)').matches) this.handleGesture()
    },
    handleGesture () {
      if (this.touchEndX < this.touchStartX - 25) {
        this.next()
      }

      if (this.touchEndX > this.touchStartX + 25) {
        this.prev()
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    lastRt = from
    next()
  },
  created () {
    this.get()
  },
  mounted () {
    window.addEventListener('keydown', this.onKey)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKey)
  }
  // TODO fetch asset, remove tags from entryitem query
}
</script>

<style lang="css" scoped>
</style>
